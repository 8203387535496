(<any> $).fn.qSpinner = function(options) {
    var $this=$(this);

    var options=$.extend({
        min: 1,
        value: 1,

        onSpin: null,
        onLazySpin: null
    }, options);

    function spinUpdate(e?, ui?) {
        var val=ui!=null && ui.value!=null ? ui.value : $this.val();

        $this.trigger('spin', val);
    }

    var timer=null;

    (<any> $this).spinner({
        min: options.min,
        max: options.max,

        spin: function(e, ui) {
            spinUpdate(e, ui);

            if (options.onLazySpin!=null) {
                if (timer!=null)
                    window.clearTimeout(timer);

                timer=window.setTimeout(function() {
                    $this.trigger('lazyspin', ui.value);
                }, 500);
            }
        },

        change: spinUpdate
    });

    if (options.onSpin!=null)
        $this.on('spin', options.onSpin);

    if (options.onLazySpin!=null)
        $this.on('lazyspin', options.onLazySpin);

    $this.siblings('.ui-spinner-up').html('+');
    $this.siblings('.ui-spinner-down').html('-');

    (<any> $this).spinner('value', options.value);

    spinUpdate();

    return $this;
};
