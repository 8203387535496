export default class AddToCart {
    private $el;

    public constructor($el: JQuery) {
        this.$el = $el;

        this.initHtml();
    }

    public initHtml(): void {
        var $this = this.$el;

        var $quantity=$('.addtocart__quantity', $this);
        var $button=$('.addtocart__button', $this);

        var adding=false;

        function reset() {
            $quantity.val(1);
        }

        function getVars() {
            var vars=$(':input', $this).serializeArray();

            if ($this.data('id')) {
                vars.push({
                    name: 'id',
                    value: $this.data('id')
                });
            }

            return vars;
        }

        function submit() {
            if (adding) {
                return;
            }

            adding=true;

            $this.addClass('addtocart--added');

            $('body').trigger('cartUpdateInit');

            $.post('/cartapi/add', getVars(), function() {
                reset();

                window.setTimeout(function() {
                    $this.removeClass('addtocart--added');

                    adding=false;
                }, 2000);

                $('body').trigger('cartUpdate');
            });
        }

        $button.click(function (e) {
            e.preventDefault();

            submit();
        });
    }
}
