declare var Slider: any;

export default class SiteProduct {
	private static initGallery() {
		let $uid = $('.productgallery');

		let slider = new Slider($uid, {
			fixed: true,
			minwidth: 140
		});

		let ix = 0;
		let $images = $('.productgallery__item');
		let $image = $('.productgallery__image');

		function setImage(url: string, force: boolean) {
			if (force) {
				$image.css({
					'background-image': 'url(' + url + ')'
				});
			} else {
				$image.fadeOut(200, function () {
					$image.css({
						'background-image': 'url(' + url + ')'
					});

					$image.fadeIn(200);
				});
			}

			$image.data('url', url);
		}

		function goToItem(i: number, force?: boolean) {
			if (i >= $images.length) {
				i = $images.length -1;
			}

			if (i < 0) {
				i = 0;
			}

			let $item = $images.eq(i);

			$images.removeClass('productgallery__item--selected');
			$item.addClass('productgallery__item--selected');

			let mi = Math.floor(slider.getMaxPerPage() / 2);
			slider.goToItem(i - mi);

			let url = $item.data('image');

			setImage(url, force);

			ix = i;
		}

		$('.productgallery__item').click(function (e) {
			goToItem($(this).index());
		});

		$image.click(function (e) {
			let url = $image.data('url');

			(<any>$).fancybox({
				href: url
			});
		});

		goToItem(0, true);

		$uid.addClass('productgallery--inited');
	}

	private static initTabs() {
		let $this = $('.producttabs');
		let $tabs = $('.producttabs__tab', $this);
		let $pages = $('.producttabs__page', $this);

		function select(ix: number) {
			let $tab = $tabs.eq(ix);
			let $page = $pages.eq(ix);

			$pages.hide();
			$page.show();

			$tabs.removeClass('producttabs__tab--selected');
			$tab.addClass('producttabs__tab--selected');
		}

		$tabs.click(function (e) {
			select($(this).index());
		});

		select(0);

		$this.addClass('producttabs--inited');
	}

	private static initFiles(btitle: string): void {
		$('.article__files a').each(function () {
			let $btn = $('<span class="file-button__button"><img src="/theme/images/icon-file.svg" class="svg-inject">' + btitle + '</span>');

			$btn.prependTo($(this));
			$btn.parent().addClass('file-button');
		});

		(<any> window).Site.discover($('.article__files'));
	}

	public static discover($parent?: JQuery): void {
		let $addtocart = $('.productheader .addtocart', $parent);
		let $quantity = $('.addtocart__quantity input', $addtocart);
		let $total = $('.addtocart__total-value', $addtocart);
		let $totalval = $('span b', $total);

		let price = parseFloat($total.data('price'));

		function update() {
			let quantity = $quantity.val();

			$totalval.text(quantity * price);
		}

		(<any> $quantity).qSpinner({
			value: $quantity.val(),

			onLazySpin: function(e, val) {
				update();
			}
		});
	}

	public static init(options: any): void {
		SiteProduct.initGallery();

		this.discover();
	}

	public static initModule(): void {
		(<any> window).SiteProduct = SiteProduct;
	}
}
